<template>
  <section class="mt-5">
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <section class="inside-section">
            <ul class="btns">
              <li>
                <router-link to="/business/category/add">
                  <v-btn color="purple" class="btn-color-white" >
                    Add Category <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </router-link>
              </li>
              <li>
                <router-link to="/business/subcategory/add">
                  <v-btn color="purple" class="btn-color-white" >
                    Add Sub Category <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </router-link>
              </li>
             
            </ul>
          </section>
        </v-col>

        <v-row class="justify-center">
          <v-col cols="12" class="pl-5">
            <v-row>
              <v-col md="6" v-if="categories.length > 0">
                <div class="boxCategory">
                  <h3>Categories</h3>
                  <ul class="listCat">
                    <li
                      v-for="(data, index) in categories"
                      :key="index + 'cat'"
                      :class="{ selected: selectedCategory == data._id }"
                    >
                      <div>
       
                          <span>{{ data.name }}</span>
                        
                        <span class="percentage">
                          <router-link
                            :to="`/business/category/add/${data._id}`"
                            class="pr-2"
                          >
                            <v-icon class="icons-action maincatin" v-if="true">
                              mdi-square-edit-outline
                            </v-icon>
                          </router-link>
                          <v-icon
                            class="icons-action maincatin"
                            @click="deleteCategory(data._id)"
                          >
                            mdi-delete
                          </v-icon>

                          <v-icon
                            v-if="data.subCategories.length > 0"
                            @click="getSubCat(data._id)"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col md="6" v-if="subcategories.length > 0">
                <div class="boxCategory">
                  <h3>Sub Categories</h3>
                  <ul class="listCat">
                    <li
                      v-for="(data, index) in subcategories"
                      :key="index + 'cat'"
                      :class="{ selected: selectedSubCategory == data._id }"
                    >
                      <div>
                        <span>
                          {{ data.name }}
                        </span>
                        <span class="percentage">
                          <router-link
                            :to="`/business/subcategory/add/${data._id}`"
                            class="pr-2"
                          >
                            <v-icon class="icons-action maincatin" v-if="true">
                              mdi-square-edit-outline
                            </v-icon>
                          </router-link>
                          <v-icon
                            class="icons-action maincatin"
                            @click="deleteSubCategory(data._id)"
                          >
                            mdi-delete
                          </v-icon>
                          <v-icon
                            v-if="data.subCategories.length > 0"
                            @click="getThirdCat(data._id)"
                          >
                            mdi-chevron-right
                          </v-icon></span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col md="4" v-if="thirdcategories.length > 0">
                <div class="boxCategory">
                  <h3>Third Categories</h3>
                  <ul class="listCat">
                    <li
                      v-for="(data, index) in thirdcategories"
                      :key="index + 'cat'"
                    >
                      <div>
                        <span>
                          {{ data.name }}
                        </span>
                        <span class="percentage">
                          <router-link
                            :to="`/thirdcategory/add/${data._id}`"
                            class="pr-2"
                          >
                            <v-icon class="icons-action maincatin" v-if="true">
                              mdi-square-edit-outline
                            </v-icon>
                          </router-link>
                          <v-icon
                            class="icons-action maincatin"
                            @click="deleteThirdCategory(data._id)"
                          >
                            mdi-delete
                          </v-icon>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col md="4" v-if="loading">
                <div class="boxCategory loading">
                  <Loading/>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Category",
  data: () => ({
    headercontent: {
      title: "Categories",
      addlink: "/category/add",
    },
    categories: [],
    subcategories: [],
    thirdcategories: [],
    loading: true,
    selectedCategory: undefined,
    selectedSubCategory: undefined,
  }),
  components:{
    Loading
  },
  methods: {
    async deleteCategory(id) {
      if (confirm("Are you sure you want to delete category ?")) {
        await this.deleteCategoryById(id);
        this.categories = this.allCategoryList.results;
      }
    },
    async deleteSubCategory(id) {
      if (confirm("Are you sure you want to delete subcategory ?")) {
        await this.deleteSubCategoryById(id);
        this.subcategories = this.allSubCategoryList.results;
      }
    },
    async deleteThirdCategory(id) {
      if (confirm("Are you sure you want to delete third subcategory ?")) {
        await this.deleteThirdCategoryById(id);
        this.thirdcategories = this.allThirdCategoryList.results;
      }
    },
    async getSubCat(data) {
      this.selectedCategory = data;
      this.subcategories = [];
      this.thirdcategories = [];
      this.loading = true;
      await this.getSubCategoryList({ category: data });
      this.subcategories = this.allSubCategoryList.results;
      this.loading = false;
    },
    async getThirdCat(data) {
      this.selectedSubCategory = data;
      this.thirdcategories = [];
      this.loading = true;
      await this.getThirdCategoryList({ category: data });
      this.thirdcategories = this.allThirdCategoryList.results;
      this.loading = false;
    },
    ...mapActions([
      "getCategoryList",
      "getSubCategoryList",
      "getThirdCategoryList",
      "deleteCategoryById",
      "deleteSubCategoryById",
      "deleteThirdCategoryById",
    ]),
  },
  async created() {
    await this.getCategoryList();
    this.categories = this.allCategoryList.results;
    this.loading = false;
  },
  computed: {
    ...mapGetters([
      "allCategoryList",
      "allSubCategoryList",
      "allThirdCategoryList",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.boxCategory {
  margin-top: 40px;
  background: #fff;
  h3 {
    background: linear-gradient(90deg, #9c00d3 0%, #5b3de8 100%);
    padding: 10px;
    color: #fff;
  }
  .listCat {
    display: flex;
    flex-flow: column;
    padding-left: 0;
    list-style: none;
    margin-left: 0;
    box-shadow: 0 4px 5px #efefef;
    .anchor {
      cursor: pointer;
    }
    li.selected {
      background: #ecebeb;
    }
    li {
      margin: 0;
      padding: 10px;
      div {
        display: flex;
        justify-content: space-between;
        .percentage {
          font-weight: 600;
        }
      }
    }
  }
}
ul{
  padding-left: 0;
  list-style: none;
}
a{
  text-decoration: none;
}
.btns {
  display: flex;
  justify-content: flex-end;
  li {
    margin-left: 10px;
  }
}
.btn-dark {
  background: #000;
  color: #fff;
  margin-top: -10px;
}
.btn-color-white {
  color: #fff;
}
.v-btn:not(.v-btn--round).v-size--small {
  height: 38px;
  min-width: 50px;
  padding: 0 12.4444444444px;
}
.loading {
  justify-content: center;
  display: flex;
  height: 100%;
  align-items: center;
  flex-flow: column;
}
</style>
